@import 'colors.scss';
@import 'fonts.scss';

* {
  font-family: FuturaMedium;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: FuturaHeavy;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p,
span {
  color: #000;
  font-family: FuturaBook;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: #000;
}

input {
  font-family: FuturaBook;
}
