@import '../../common.scss';

#modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 456px;
  height: 261px;

  border-radius: 12px;
  background-color: #ffffff;

  img {
    margin-bottom: 15px;
  }
}

#modal-info {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#delete-title {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: $app-black;
  margin-top: 10px;
  text-align: center;
}

#delete-description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b14;
  text-align: center;
}

.button-modal {
  width: 140px;
  height: 46px;
  margin: 0 0 0 6px;
  padding: 13.5px 31px 13.5px 32px;
  border-radius: 66px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 15px;
}

#candel-btn-modal {
  background-color: #ffffff;
  border: solid 1px $app-black;

  span {
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.2;
    letter-spacing: normal;
    text-align: left;
    color: $app-black;
  }
}

#proceed-btn-modal {
  background-color: #f83131;

  span {
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
}
