@import '../../../..//common.scss';

#forgot-password {
  @extend .disable-select;
  color: $app-orange;
  float: right;
  font-family: FuturaBook;
  font-weight: bold;
}

#forgot-password:before {
  content: url('/images/text-arrow-right-enabled.svg');
  margin-right: 7px;
}

.password-input {
  margin-bottom: 15px !important;
}

#stay-logged-in {
  margin: 45px 0 0 0;
  position: relative;
  float: left;
  width: 100%;
}

#stay-logged-in-label {
  display: inline-block;
  width: 140px;
}

#buttons-wrapper {
  display: inline-block;
  margin: 25px 0;
}

#btn-active {
  width: 160px;
  height: 46px;
  background-color: $app-orange;
  color: #fff;
  border-radius: 66px;
  margin-right: 10px;
  margin-top: 10px;
}

#btn-fb-login {
  width: 160px;
  height: 46px;
  background-color: #3578e5;
  color: #fff;
  border-radius: 66px;
  font-family: LeagueSpartanBold;
  display: table-cell;
  vertical-align: middle;
  padding: 13px;
  margin-top: 10px;
}

#btn-active,
#btn-fb-login {
  box-shadow: none !important;
}

@media (max-width: 1280px) {
  #btn-active,
  #btn-fb-login {
    width: 145px;
  }
}

@media (max-width: 1024px) {
  #btn-active,
  #btn-fb-login {
    width: 130px;
  }
}
