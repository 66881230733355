$app-orange: #ff9200;

$app-gray: #a8afb8;
$app-medium-gray: #e8ebee;
$app-light-gray: #f7f8fb;

$app-black: #212120;

$app-purple: #5b66ed;

$app-light-blue: #49dad1;
