@import '../../common.scss';

#map {
  width: 100%;
  height: calc(100% - 55px); // - $headerHeight
}

.pac-container {
  margin-top: 5px;
  border-radius: 10px;

  .pac-item {
    height: 45px;
    line-height: 45px;
    padding: 0 30px;
    font-family: FuturaBook;

    .pac-icon {
      margin-top: 12px;
      display: none;
    }
  }

  .pac-item:hover {
    background-color: rgba($app-gray, 0.2);
    cursor: pointer;
  }
}

.mapMarker {
  width: 23px;
  height: 34px;
  background-image: url('/images/map/marker_1.svg');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.mapMarker img {
  @extend .disable-select;
  margin-top: 3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.cluster-0 div {
  outline: none;
  margin-top: 4px;
}

.cluster-1 div,
.cluster-2 div {
  outline: none;
  margin-top: 8px;

  span {
    color: #fff;
  }
}

.cluster span {
  font-family: FuturaMedium;
}

.mapImage {
  @extend .disable-select;
  max-width: 150px !important;
  max-height: 130px !important;
}
