@import '../../common.scss';

#add-business-top {
  padding: 15px;
  text-align: left;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

.add-input {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
}

#add-business-body {
  @extend .custom-scrollbar-thinner;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

// Start image code
.browse-logo-wrapper {
  width: 100px;
  height: 100px;
  display: flex;
  margin: 12px 0;
  border-radius: 12px;
  border: solid 1px $app-medium-gray;
  background-color: #fff;
  position: relative;

  .browse-logo-round-wrapper {
    height: 28px;
    width: 28px;
    border-radius: 28px;
    top: 0;
    right: 0;
    margin: -13px -13px 0 0;
    position: absolute;
    cursor: pointer;
    border: solid 0.5px $app-medium-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;

    #plus {
      position: absolute;
      width: 11px;
      height: 11px;
      object-fit: contain;
      color: $app-black !important;
    }
  }

  #delete-image-button {
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid $app-medium-gray;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -13px;
    left: 83px;

    #delete-image {
      width: 15px;
      height: 15px;
    }
  }

  .browse-logo-icon {
    @extend .disable-select;

    .browse-logo-input {
      display: none;
    }

    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 19.4px;
      height: 16.1px;
      display: flex;
    }

    p {
      font-family: FuturaBook;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: left;
      color: $app-gray;
      display: flex;
      margin: 0;
    }
  }
}

.preview-logo-wrapper {
  @extend .disable-select;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;

  #preview-logo {
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid $app-black;
  }
}
// End image code

#add-business-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

#save-btn-disabled,
#save-btn-enabled,
#cancel-button {
  @extend .disable-select;
  width: 100px !important;
  height: 36px !important;
  border-radius: 66px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: normal;
  margin-top: 0 !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  cursor: pointer;
}

#save-btn-enabled {
  color: #ffffff !important;
  background-color: $app-orange !important;
  margin-right: 10px !important;
  margin-left: 5px !important;
}

#save-btn-disabled {
  color: #ffffff !important;
  background-color: #c2ccda !important;
  margin-right: 10px !important;
  margin-left: 5px !important;
}

#cancel-button {
  color: $app-black !important;
  background-color: #fff !important;
  border: solid 0.5px $app-black !important;
  margin-right: 5px !important;
}

input[type='file'] {
  display: none;
}

#select-business {
  margin-top: 5px;
  margin-bottom: 19px;
  display: flex;
}
