@import '../../common.scss';

#prizes-top {
  padding: 15px;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  position: fixed;
  position: relative;
  left: 0px;
  right: 0px;
}

#prizes-content {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 10px;
  left: 0px;
  right: 0px;
  overflow: auto;
  margin: 10 15px;
}

// search box
#prize-code-search-box-wrapper,
#prize-code-search-box {
  width: 100%;
  margin-bottom: 30px;
}

#prize-code-search-box-wrapper {
  padding: 0 20px;
  border: none;
  z-index: 1;
}

#prize-code-search-button {
  @extend .disable-select;
  width: 55px;
  height: 20px;
  background-color: $app-purple;
  border-radius: 27px;
  color: #fff;
  font-family: FuturaMedium;
  font-size: 12px;
  // font-weight: 900;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 30px;
}

#prize-code-clear-search-input {
  width: 20px;
  height: 20px;
  background-image: url('/images/map/x.svg');
  background-size: 100%;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 30px;
  pointer-events: visible;
}

#prize-code-search-box {
  @extend .disable-select;
  padding: 0 0 0 15px;
  background-color: #fff;
  font-family: FuturaMedium;
  font-size: 14px;
  font-weight: 300;
  text-overflow: ellipsis;
  border-radius: 27px;
  border: none;
  display: block;
  height: 35px;
  top: -1px !important;
  border: 1px solid $app-medium-gray;

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-moz-placeholder,
  &:-moz-placeholder {
    font-family: FuturaBook;
  }

  &:focus {
    outline: 0;
  }
}

// page content
#prize-code-user-info {
  margin: 20px 15px;

  .user-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: -25px;
  }

  .prize-code-user-name-email {
    display: inline-block;

    p {
      margin: 0;
    }
  }
}

.row-left-column {
  display: inline-block;
  width: 50%;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: $app-gray;
}

.row-right-column {
  display: inline-block;
  width: 50%;
  padding-top: 4px;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $app-black;

  img {
    width: 22.3px;
    height: 22.3px;
    margin-right: 9px;
    margin-left: 0;
  }
}

.page-row {
  margin: 1px;
  position: relative;
  overflow: hidden;
  margin: 15px 10px;
}

.prize-challenge-task {
  height: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 10px 0;
}

.prize-challenge-task-position {
  background-color: $app-purple;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 10px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#prize-code-not-found {
  text-align: center;
}

#prize-code-status-wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

#prize-given-checkmark {
  color: red !important;
}

#prize-code-status {
  width: 235px;
  text-align: center;
}

.give-prize-btn {
  @extend .disable-select;
  width: 100px;
  height: 30px;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 66px;
  background-color: $app-orange;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: FuturaBook;
}
