@import '../../common.scss';

#challenge-details-top {
  padding: 15px;
  text-align: left;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

#challenge-details-body {
  @extend .custom-scrollbar-thinner;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

#challenge-details-images-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 28px;
}

.challenge-details-image {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}

#delete-image-button {
  position: absolute;
  top: -4px;
  left: 49px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

#challenge-name {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: $app-black;
}

.row-left-column {
  display: inline-block;
  width: 50%;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: $app-gray;
}

.row-right-column {
  display: inline-block;
  width: 50%;
  padding-top: 4px;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $app-black;

  img {
    width: 22.3px;
    height: 22.3px;
    margin-right: 9px;
    margin-left: 0;
  }
}

.black {
  color: $app-black !important;
}

.page-row {
  margin: 1px;
  position: relative;
  overflow: hidden;
  margin: 15px 10px;
}

#challenge-details-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0px;
}

#delete-challenge-button,
#edit-challenge-button {
  @extend .disable-select;
  width: 100px !important;
  height: 36px !important;
  border-radius: 66px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: normal;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  cursor: pointer;
}

#edit-challenge-button {
  color: #ffffff !important;
  background-color: $app-orange !important;
  margin-right: 10px !important;
  margin-left: 5px !important;
}

#delete-challenge-button {
  color: $app-black !important;
  background-color: #fff !important;
  border: solid 0.5px $app-black !important;
  margin-right: 5px !important;
}

.challenge-tasks {
  @extend .disable-select;
  cursor: pointer;
}

.challenge-task {
  background-color: $app-light-gray;
  border-radius: 12px;
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 10px 0;
}

.challenge-task-position {
  background-color: $app-purple;
  color: #fff;
  border-radius: 50%;
  margin: 0 10px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
