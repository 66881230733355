@import '../../common.scss';

.menu-option {
  @extend .disable-select;
  font-family: FuturaBook;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.55;
  letter-spacing: 0.12px;
  color: $app-gray;
  padding: 0 5px;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    color: $app-gray;
  }
}

.menu-option-selected {
  @extend .menu-option;

  span {
    color: black;
  }
}

.logo-image {
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  width: 14px !important;
  height: 14px !important;
  margin: 0 10px;
}

#none-businesses-alert-menu {
  position: absolute;
  top: -5px;
  right: 14px;

  width: 13px;
  height: 13px;
  margin: 0 5px 14.6px 6.6px;
  padding: 1.8px 5.6px 2.2px 5.4px;
  object-fit: contain;

  span {
    position: absolute;
    top: 16px;
    right: -3.2px;
    width: 3px;
    height: 12px;
    font-size: 10px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
}
