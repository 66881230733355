@use 'sass:math';
@import '../../common.scss';

$headerHeight: 55px;
$searchBoxWidth: 360px;
$menuPageWidth: 135px;
$firstPageWidth: 400px;
$secondPageWidth: 400px;
$thirdPageWidth: 400px;

#header-bar {
  width: 100%;
  background-color: #fff;
  height: $headerHeight;
}

#pinapp-logo-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 10px;

  span {
    display: inline-block;
    margin: 0 0 0 25px;
    color: $app-orange;
    font-family: FuturaHeavy;
    font-size: 16px;
    text-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  }
}

// logo & left page switch
#pinapp-logo-map {
  @extend .disable-select;
  position: relative;
  display: inline-block;
  z-index: 1;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  left: 16px;
  background-color: $app-orange;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;

  #pinapp-logo {
    margin: 7px 11px;
    width: 13px;
    height: 21px;
  }

  #pinapp-business-logo {
    position: absolute;
    right: -5px;
    top: -1px;
  }
}

#pinapp-go-logo {
  @extend .disable-select;
  margin: 10px 20px;
  width: 90px;
}

.show-first-page-icon {
  @extend .disable-select;
  width: 15px;
  height: 15px;
}

.hide-first-page-icon {
  @extend .disable-select;
  width: 23px;
  height: 23px;
}

#pages-switch {
  position: absolute;
  z-index: 1;
  top: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  left: calc(50% - #{math.div($searchBoxWidth, 2)} - 50px);
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  //   &.first-page-open {
  //   }
}

// search box
#map-search-box-wrapper,
#map-search-box {
  width: $searchBoxWidth;
}

#map-search-box-wrapper {
  position: absolute;
  top: 10px;
  margin-left: calc(50% - #{math.div($searchBoxWidth, 2)});
  padding: 20px;
  border: none;
  z-index: 1;
  pointer-events: none;

  // &.first-page-open {
  // }
}

#clear-search-input {
  width: 20px;
  height: 20px;
  background-image: url('/images/map/x.svg');
  background-size: 100%;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 8px;
  right: 20px;
  pointer-events: visible;
}

#map-search-box {
  // $leftPixels: 130;
  @extend .disable-select;
  margin-top: 13px;
  margin-left: calc(50% - #{math.div($searchBoxWidth, 2)});
  padding: 12px 40px 12px 30px;
  background-color: #fff;
  font-family: FuturaMedium;
  font-size: 14px;
  font-weight: 300;
  text-overflow: ellipsis;
  border-radius: 27px;
  border: none;
  display: block;
  height: 33px;
  position: fixed !important;
  top: -1px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;

  // &.first-page-open {
  // }

  &::-webkit-input-placeholder {
    font-family: FuturaBook;
  }
  &::-moz-placeholder {
    font-family: FuturaBook;
  }
  &:-ms-input-placeholder {
    font-family: FuturaBook;
  }
  &:-moz-placeholder {
    font-family: FuturaBook;
  }
}

#map-search-box:focus {
  outline: 0;
}

.page {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  background-color: $app-light-gray;
  height: calc(100% - #{$headerHeight});
}

// menu page
#menu-page {
  @extend .page;
  top: $headerHeight;
  left: 0;
  width: $menuPageWidth;
  padding: 11px 0;
  background-color: #ffffff;
  border-right: 5.5px solid #f6f5f8;
  border-top: 5.5px solid #f6f5f8;
  div:hover {
    background-color: rgba($app-gray, 0.2);
    cursor: pointer;
    color: #000;
  }
}

// first page
#first-page {
  @extend .page;
  @extend .custom-scrollbar-thinner;
  top: $headerHeight;
  left: $menuPageWidth;
  width: $firstPageWidth;
  overflow-y: scroll;
  background-color: #ffffff;
  border-top: 5.5px solid #f6f5f8;
}

// second page
#second-page {
  @extend .page;
  @extend .custom-scrollbar-thinner;
  top: $headerHeight;
  left: $menuPageWidth + $firstPageWidth;
  width: $secondPageWidth;
  overflow-y: scroll;
  background-color: #ffffff;
  border-top: 5.5px solid #f6f5f8;
}

// third page
#third-page {
  @extend .page;
  @extend .custom-scrollbar-thinner;
  top: $headerHeight;
  left: $menuPageWidth + $firstPageWidth + $secondPageWidth;
  width: $thirdPageWidth;
  overflow-y: scroll;
  background-color: #ffffff;
  border-top: 5.5px solid #f6f5f8;
}

// user info
#user-info-and-menu {
  @extend .disable-select;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 16px;

  .profile-picture {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  }

  .user-name {
    color: #000;
    margin-left: 10px;
    font-family: FuturaBook;
    font-size: 14px;
    font-weight: 900;
  }

  #user-menu-switch {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0px 7px;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;

    img {
      @extend .disable-select;
      width: 10px;
    }
  }
}

#user-info {
  float: right;
}

// user menu
#user-menu {
  background-color: #fff;
  border-radius: 12px;
  margin-top: 50px;
  padding: 7px 0;

  div {
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    color: $app-gray;
    font-family: FuturaBook;
  }

  div:hover {
    background-color: rgba($app-gray, 0.2);
    cursor: pointer;
    color: #000;
  }
}
