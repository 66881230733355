@import '../../common.scss';

#location-details-top {
  padding: 15px;
  text-align: left;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

#location-details-body {
  @extend .custom-scrollbar-thinner;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

#location-details-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0px;
}

#location-name {
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: 0.18px;
  text-align: left;
  color: $app-black;

  margin-top: 25px;
  margin-left: 18px;
  margin-bottom: 22px;
  padding-bottom: 4px;

  display: inline-block;
}

#edit-button-loc {
  cursor: pointer;
  display: inline-flex;
  vertical-align: top;
  padding-top: 2px;
  margin-left: -7px;
  position: relative;
}

#edit-button-loc .edit-button-hover {
  display: none;
  position: absolute;
  top: 1px;
  left: 0;
}

#edit-button-loc:hover .edit-button-hover {
  display: inline;
}

#location-details-images-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 18px;
  margin: 10px;
}

.location-details-image {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}

.business-type-icon {
  width: 20px !important;
  height: 20px !important;
}

#page-bottom {
  height: 92px;
  padding: 6.8px 61px 22.3px 15px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
}

#delete-location-button,
#edit-location-button {
  @extend .disable-select;
  width: 100px !important;
  height: 36px !important;
  border-radius: 66px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: normal;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  cursor: pointer;
}

#edit-location-button {
  color: #ffffff !important;
  background-color: $app-orange !important;
  margin-right: 10px !important;
  margin-left: 5px !important;
}

#delete-location-button {
  color: $app-black !important;
  background-color: #fff !important;
  border: solid 0.5px $app-black !important;
  margin-right: 5px !important;
}

.open-hours {
  border: solid 0.5px $app-gray;
  border-radius: 12px;
  padding: 15px 20px;
  margin: 10px 0;
  display: flex;
  gap: 30px;
}

.open-text {
  display: flex;
  color: $app-purple;
  font-weight: bold;
  font-size: 14px;
  padding: 3px 0;
}

.open-hours-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.open-hours-item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 3px 0;
}

.closed-text {
  margin-right: 58px;
}
