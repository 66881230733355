@import url('/css/bootstrap.min.css');
@import url('/css/fontawesome.min.css');
@import 'styles/html.scss';
@import 'styles/checkbox.scss';
@import 'styles/modal.scss';

.disable-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}

.custom-scrollbar {
  /* Width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $app-light-gray;
    border-radius: 0 12px 12px 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $app-gray;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: desaturate(darken($app-gray, 5%), 5%);
  }
}

.custom-scrollbar-thinner {
  /* Width */
  &::-webkit-scrollbar {
    width: 5.5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $app-light-gray;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $app-gray;
    border-radius: 12px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: desaturate(darken($app-gray, 5%), 5%);
  }
}

.text-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(#aebac3, 0.5);
  margin: 30px 0;
}

.text-input:focus {
  box-shadow: none;
  border-bottom: 1px solid rgba(#aebac3, 0.5);
}

.text-input::placeholder {
  color: $app-gray;
  font-family: FuturaBook;
}

.text-multiline-input {
  border: 0.5px solid rgba(#aebac3, 0.5);
}

.text-multiline-input:focus {
  border: 0.5px solid rgba(#aebac3, 0.5);
  box-shadow: none;
  border-bottom: 1px solid rgba(#aebac3, 0.5);
}

.text-multiline-input::placeholder {
  color: $app-gray;
  font-family: FuturaBook;
}
