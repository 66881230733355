@import 'colors.scss';

.checkbox {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: block;
}

.checkbox input[type='checkbox'] {
  width: auto;
  opacity: 0.00000001;
  position: absolute;
  left: 0;
  margin-left: -20px;
}

.checkbox label {
  position: relative;
}

.checkbox label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 18px;
  height: 18px;
  transition: transform 0.28s ease;
  border-radius: 3px;
  background-color: $app-orange;
}

// check icon
.checkbox label:after {
  content: '';
  display: block;
  width: 12px;
  height: 6px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-45deg) scale(0);
  position: absolute;
  top: 8px;
  left: 7px;
}

.checkbox input[type='checkbox']:checked ~ label::after {
  -webkit-transform: rotate(-45deg) scale(1);
  transform: rotate(-45deg) scale(1);
}

.checkbox label {
  min-height: 35px;
  display: block;
  padding-left: 35px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.checkbox label span {
  position: absolute;
  top: 36%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: FuturaBook;
  font-size: 14px;
  font-weight: bold;
}

.checkbox input[type='checkbox']:focus + label::before {
  outline: 0;
}
