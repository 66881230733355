@import '../../common.scss';

.pin-wrapper {
  float: left;
  width: 100%;
}

.pin-wrapper:not(:last-child) {
  margin-bottom: 10px;
}

.pin-top {
  padding: 15px;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
}

.pin-profile-picture {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
}

.pin-top-middle-data {
  display: inline-block;
  vertical-align: middle;

  .name {
    font-family: FuturaHeavy;
    font-size: 18px;
  }

  .stars-count {
    font-family: FuturaBook;
    font-size: 16px;
    color: $app-gray;
  }
}

.pin-top-right-data {
  vertical-align: middle;
  float: right;

  .time-span {
    font-family: FuturaBook;
    font-size: 16px;
    color: $app-gray;
  }
}

.pin-image {
  @extend .disable-select;
  width: 100%;
}

.pin-bottom {
  padding: 15px;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  font-size: 16px;

  width: 100%;
  display: flex;
  flex-direction: row;

  .location {
    font-family: FuturaBook;
    margin: 0;
  }

  .text {
    font-family: FuturaBook;
    color: $app-gray;
    margin-top: 13px;
    margin-bottom: 0;
  }
}

.star-wrapper img {
  cursor: pointer;
}

.location-desc {
  width: 100%;
}
