@import '../../common.scss';

.custom-select-container {
  @extend .disable-select;
  margin-left: -5px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: $app-gray;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
  width: 300px;

  li {
    background-color: #fff;
  }

  li:hover {
    background-color: $app-gray;
  }
}

.select-options-wrapper {
  @extend .custom-scrollbar-thinner;
  margin-left: -5px;
  overflow-y: scroll;
  max-height: 160px;
  border-radius: 12px;
  box-shadow: 2px 3px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.rselect-options {
  margin: 0;
  padding: 0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

.rselect-option {
  margin: 0;
  padding-left: 17px;
  height: 38px;
  list-style-type: none;
  cursor: pointer;

  display: flex;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.14px;
    text-align: left;
    color: $app-black;
  }

  img {
    width: 22px;
    height: 22px;
    margin-right: 9px;
    margin-left: 0;
  }
}

#current-selected-option-default {
  margin-left: 4px;

  span {
    font-size: 16px;

    line-height: 1.63;
    color: $app-gray;
  }
}

#current-selected-option {
  margin-left: 4px;
  background-color: white;

  img {
    width: 22px;
    height: 22px;
    margin-top: -4px;
    margin-right: 9px;
  }

  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: $app-black;
  }
}

.multiple-select-label span {
  display: block;
  margin-left: 5px;
}
