@import '../../common.scss';

#add-location-top {
  padding: 15px;
  text-align: left;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

.add-input {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
}

#add-location-body {
  @extend .custom-scrollbar-thinner;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

#select-business {
  margin-top: 5px;
  margin-bottom: 19px;
  display: flex;
  cursor: pointer;

  .select-arrow {
    display: inline-block;
    margin-left: auto;
    width: 10px;
    height: 10px;
    margin-top: 9px;
  }
}

#select-type {
  margin-top: 5px;
  margin-bottom: 19px;
  display: flex;
  cursor: pointer;

  .select-arrow {
    display: inline-block;
    margin-left: auto;
    width: 10px;
    height: 10px;
    margin-top: 9px;
  }
}

#location-description {
  height: 118px;
  border-radius: 12px;
  border: solid 0.5px $app-gray;
}

#characters-restriction {
  text-align: right;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: normal;
  color: $app-orange;
}

#location-images-wrapper {
  display: flex;
  justify-content: flex-start;
  gap: 28px;

  #location-image-wrapper {
    position: relative;
    float: left;

    .location-image {
      width: 100px;
      height: 100px;
      border-radius: 12px;
    }
  }

  #delete-image-button {
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid $app-medium-gray;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -13px;
    left: 83px;

    #delete-image {
      width: 15px;
      height: 15px;
    }
  }

  .browse-photo-wrapper {
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 12px;
    border: solid 1px $app-medium-gray;
    background-color: #fff;
    position: relative;

    .browse-photo-round-wrapper {
      height: 28px;
      width: 28px;
      border-radius: 28px;
      top: 0;
      right: 0;
      margin: -13px -13px 0 0;
      position: absolute;
      cursor: pointer;
      border: solid 0.5px $app-medium-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      z-index: 1;

      #plus-button {
        position: absolute;
        width: 11px;
        height: 11px;
        object-fit: contain;
        color: $app-black !important;
      }
    }

    .browse-photo-icon {
      @extend .disable-select;

      .browse-photo-input {
        display: none;
      }

      position: relative;
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 19.4px;
        height: 16.1px;
        display: flex;
      }

      p {
        font-family: FuturaBook;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: $app-gray;
        display: flex;
        margin: 0;
      }
    }
  }
}

p.add-page-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: $app-gray;
  margin-left: 7.4px;
  margin-bottom: 10px;
}

#photo-camera {
  position: absolute;
  top: 20px;
  right: 17.1px;
}

#min-photo-number {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  color: $app-orange;
  margin-left: 2.5px;
  margin-top: 0;
  margin-bottom: 0;
}

#add-location-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

#save-btn-enabled {
  @extend .disable-select;
  width: 160px;
  height: 46px;
  margin: 15.8px 0 1.2px;
  padding: 13.9px 40px 13.1px 40.1px;
  border-radius: 66px;
  background-color: $app-light-blue;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  color: #ffffff;
}

#save-btn-disabled {
  @extend .disable-select;
  width: 160px;
  height: 46px;
  margin: 15.8px 0 1.2px;
  padding: 13.9px 40px 13.1px 40.1px;
  border-radius: 66px;
  background-color: #c2ccda;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;

  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  color: #ffffff;
}

#add-open-hours-button {
  @extend .disable-select;
  width: 100px !important;
  height: 36px !important;
  border-radius: 66px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: normal;
  margin-top: 0 !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  cursor: pointer;
  color: $app-black !important;
  background-color: #fff !important;
  border: solid 0.5px $app-black !important;
  margin-right: 5px !important;
  margin-bottom: 15px;
}
