@font-face {
  font-family: FuturaBook;
  src: url('/fonts/FuturaBook.TTF');
}

@font-face {
  font-family: FuturaMedium;
  src: url('/fonts/FuturaMedium.TTF');
}

@font-face {
  font-family: FuturaHeavy;
  src: url('/fonts/FuturaHeavy.TTF');
}

@font-face {
  font-family: LeagueSpartanBold;
  src: url('/fonts/LeagueSpartanBold.otf');
}
