@import '../../common.scss';

.contact-us-email {
  font-size: 14px;
}

.contact-message {
  @extend .custom-scrollbar;
  min-height: 100px;
  max-height: 125px;
}

#email-sent-message {
  margin: 200px 0;
  text-align: center;

  img {
    margin-top: 20px;
  }
}
