@import '../../common.scss';

#add-open-hours-top {
  padding: 15px;
  text-align: left;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

#add-open-hours-wrapper {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 80px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
  padding: 0 18px;
}

#select-open-hours {
  margin-top: 5px;
  margin-bottom: 19px;
  display: flex;
  cursor: pointer;

  .select-arrow {
    display: inline-block;
    margin-left: auto;
    width: 10px;
    height: 10px;
    margin-top: 9px;
  }
}

#save-btn-enabled {
  @extend .disable-select;
  width: 160px;
  height: 46px;
  margin: 15.8px 0 1.2px;
  padding: 13.9px 40px 13.1px 40.1px;
  border-radius: 66px;
  background-color: $app-light-blue;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  color: #ffffff;
}

#add-open-hours-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.open-hours-day {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;

  span:first-child {
    width: 40%;
  }
  span:nth-child(2),
  span:nth-child(3) {
    width: 30%;
    text-align: center;
  }
}

.checkbox {
  margin: 0 !important;

  label span {
    font-weight: normal !important;
    font-size: 16px !important;
  }
}

.checkbox-unchecked {
  @extend .checkbox;

  label:before {
    background-color: #fff !important;
    border: 1px solid $app-gray;
  }

  label span {
    color: $app-gray;
  }
}

.closed-text {
  color: $app-gray;
  height: 35px;
}

#select-time-opening,
#select-time-closing {
  margin-top: 5px;
  margin-bottom: 19px;
  display: flex;
  width: 85px;

  .select-arrow {
    display: inline-block;
    margin-left: auto;
    width: 10px;
    height: 10px;
    margin-top: 9px;
    cursor: pointer;
  }

  .select-options-wrapper {
    width: 65px;
  }

  .select-arrow {
    margin-left: -8px;
  }

  #current-value-select {
    color: #000;
  }
}
