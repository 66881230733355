@import '../../common.scss';

#welcome-left {
  position: absolute;
  background-color: $app-orange;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 20px;
}

#welcome-left > * {
  color: #fff;
}

#text-welcome {
  margin: 0;
}

#text-pinapp-tech {
  margin: 0;
  letter-spacing: 3.64px;
}

#text-motto {
  margin: 54px 0 0 0;
  letter-spacing: 3.52px;
}

#text-you-can-discover,
#text-mission {
  margin: 13px 0 0 0;
  letter-spacing: 3.84px;
}

#text-you-can-discover {
  margin-top: 90px;
}

#download-buttons {
  margin: 40px 0 0 0;
}

#google-download-banner,
#apple-download-banner {
}

#google-download-banner {
  margin-right: 9px;
}

#welcome-right {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
}

#pinapp-logo-welcome {
  width: 64px;
  height: 98px;
  margin-top: 54px;
  margin-bottom: 10px;
}

#contact-us-link,
#login-signup-link {
  @extend .disable-select;
  float: right;
  margin: 5px 20px 0 0;
  font-family: FuturaBook;
  font-weight: bold;
}

#contact-us-link:before {
  content: url('/images/text-arrow-right.svg');
  margin-right: 7px;
}

#login-signup-link:before {
  content: url('/images/text-arrow-left.svg');
  margin-right: 7px;
}

#welcome-right-page {
  margin: 90px 90px 0 90px;
}

#welcome-tabs-wrapper {
  margin-bottom: 65px;
}

#login-tab,
#signup-tab {
  @extend .disable-select;
  display: inline-block;
}

#login-tab {
  margin-right: 20px;
}

.active-text {
  color: $app-orange;
}

.scrollable-wrapper {
  @extend .custom-scrollbar;
  max-height: 550px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

@media (max-width: 1280px) {
  #welcome-right-page {
    margin: 90px 45px 0 45px;
  }
}

@media (max-width: 1024px) {
  #welcome-right-page {
    margin: 90px 15px 0 15px;
  }
}

@media (max-width: 767px) {
  .row {
    margin: 0 !important;
  }

  #welcome-left,
  #welcome-right {
    position: relative;
    min-width: 100%;
  }

  #pinapp-logo-welcome {
    margin-top: 0;
  }
}
