@import '../../common.scss';

.approvals-top {
  padding: 15px;
  text-align: center;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  position: fixed;
  position: relative;
  left: 0px;
  right: 0px;
}

.no-step-found {
  line-height: 1.38;
  letter-spacing: 0.16px;
  text-align: center;
  padding: 0 50px;

  p {
    margin: 20px 0;
  }
}

.approvals-body {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0;
  right: 0;
  overflow: auto;
  padding-top: 1px;
}

.approvals-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: right;
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;

  #approve-all-button {
    @extend .disable-select;
    width: 160px;
    height: 46px;
    border-radius: 66px;
    background-color: $app-orange;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: FuturaBook;
    margin-right: 25px;

    span {
      color: #fff;
    }
  }

  #approve-all-button:hover {
    border-radius: 66px;
    border: solid 1px $app-orange;
  }
}
