@import '../../common.scss';

code {
  color: #000 !important;
}

.change-language {
  color: $app-orange !important;
  cursor: pointer;
  margin-right: 20px;
}

.container {
  padding: 15px;
}
