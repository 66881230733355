@import '../../common.scss';

#challenge-task-top {
  padding: 15px;
  text-align: left;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

#challenge-task-wrapper {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
  padding: 0;
}

.page-row {
  margin: 1px;
  position: relative;
  overflow: hidden;
  margin: 15px 10px;
}

.row-left-column {
  display: inline-block;
  width: 50%;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: $app-gray;
}

.row-right-column {
  display: inline-block;
  width: 50%;
  padding-top: 4px;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $app-black;

  img {
    width: 22.3px;
    height: 22.3px;
    margin-right: 9px;
    margin-left: 0;
  }
}

.challenge-task-image {
  width: 100px;
  height: 100px;
  border-radius: 12px;
}
