@import '../../common.scss';

.challenges-top {
  padding: 15px;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  position: fixed;
  position: relative;
  left: 0px;
  right: 0px;
}

.no-challenge-found {
  line-height: 1.38;
  letter-spacing: 0.16px;
  padding: 0 50px;

  p {
    margin-top: 20px;
  }
}

#business-name-challenges {
  margin-bottom: 30px;
  font-size: 16px;
}

.challenges-list {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
  padding-top: 1px;
}

.challenges-bottom {
  height: 92px;
  padding: 10.5px 28.8px 9.5px 28.1px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0px;

  .add-btn {
    @extend .disable-select;
    width: 100px;
    height: 36px;
    margin: 2px 0 2.9px 24.6px;
    padding: 10.5px 28.8px 9.5px 28.1px;
    border-radius: 66px;
    background-color: $app-orange;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.8;
    letter-spacing: normal;
    text-align: left;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: FuturaBook;
  }
}
