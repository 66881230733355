@import '../../common.scss';

#business-details-top {
  padding: 15px;
  text-align: left;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

#business-details-body {
  @extend .custom-scrollbar-thinner;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  position: absolute;
  top: 57px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;

  #logo-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    border: 1px solid $app-medium-gray;
    position: relative;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#business-details-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 0;
  right: 0px;
}

.business-logo {
  @extend .disable-select;
  border-radius: 12px;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

#delete-image-button {
  position: absolute;
  left: 70px;
  top: -25px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  cursor: pointer;

  #delete-image {
    width: 56px;
    height: 56px;
    border-radius: 56px;
  }
}

.row-left-column {
  display: inline-block;
  width: 50%;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: $app-gray;
}

.row-right-column {
  display: inline-block;
  width: 50%;
  padding-top: 4px;
  white-space: normal;
  vertical-align: top;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: $app-black;

  img {
    width: 22.3px;
    height: 22.3px;
    margin-right: 9px;
    margin-left: 0;
  }
}

.page-row {
  margin: 1px;
  position: relative;
  overflow: hidden;
  margin: 15px 10px;
}

#delete-business-button,
#edit-business-button {
  @extend .disable-select;
  width: 100px !important;
  height: 36px !important;
  border-radius: 66px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: normal;
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  cursor: pointer;
}

#edit-business-button {
  color: #ffffff !important;
  background-color: $app-orange !important;
  margin-right: 10px !important;
  margin-left: 5px !important;
}

#delete-business-button {
  color: $app-black !important;
  background-color: #fff !important;
  border: solid 0.5px $app-black !important;
  margin-right: 5px !important;
}

#add-business-bottom #delete-business-button:hover {
  border-radius: 66px;
  border: solid 1px $app-orange;
}
