@import '../../../../common.scss';

#reset-password-text {
  font-family: FuturaBook;
  font-weight: bold;
  color: $app-orange;
}

#btn-reset-password {
  width: 160px;
  height: 46px;
  background-color: $app-orange;
  color: #fff;
  border-radius: 66px;
  margin-right: 10px;
  margin-top: 40px;
  box-shadow: none !important;
}

.error {
  font-family: FuturaBook;
  position: absolute;
  color: red;
}
