@import '../../common.scss';

.ongoing-challenges-top {
  display: flex;
  align-items: stretch;
  padding: 15px;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  position: fixed;
  position: relative;
  left: 0px;
  right: 0px;
}

.no-challenge-found {
  line-height: 1.38;
  letter-spacing: 0.16px;
  text-align: center;
  padding: 0 50px;

  p {
    margin-top: 20px;
  }
}

.see-all-submits-button {
  outline: none !important;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  position: absolute;
  right: 20px;
  top: 20px;
  color: $app-orange;
  border: none;
  background-color: #fff;
}

.ongoing-challenges-body {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding-top: 1px;
}
