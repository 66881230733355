@import '../../../../common.scss';

#btn-send-email {
  width: 160px;
  height: 46px;
  background-color: $app-orange;
  color: #fff;
  border-radius: 66px;
  margin-right: 10px;
  margin-top: 20px;
  box-shadow: none !important;
}

#forgot-your-password,
#forgot-your-password-instructions {
  font-family: FuturaBook;
  font-weight: bold;
}

#forgot-your-password {
  color: $app-orange;
}

#forgot-your-password-instructions {
  padding-bottom: 40px;
}

.email-send-message {
  font-family: FuturaBook;
  position: absolute;
  margin-top: -20px;
}

.error {
  color: red;
}
