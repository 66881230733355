@import '../../../..//common.scss';

.password-input:last-child {
  margin-bottom: 15px !important;
}

#privacy-policy {
  @extend .disable-select;
  color: $app-orange;
  font-family: FuturaBook;
  font-weight: bold;
  float: right;
}

#privacy-policy:before {
  content: url('/images/text-arrow-right-enabled.svg');
  margin-right: 7px;
}

#btn-signup {
  width: 160px;
  height: 46px;
  background-color: $app-orange;
  color: #fff;
  border-radius: 66px;
  margin-right: 10px;
  margin-top: 60px;
  box-shadow: none !important;
}
