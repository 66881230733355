@import '../../common.scss';

#add-challenge-tasks-top {
  padding: 15px;
  text-align: left;
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
}

#add-challenge-task-wrapper {
  @extend .custom-scrollbar-thinner;
  overflow-y: scroll;
  position: absolute;
  top: 80px;
  bottom: 92px;
  left: 0px;
  right: 0px;
  overflow: auto;
  padding: 0 18px;
}

#select-task {
  margin-top: 5px;
  margin-bottom: 19px;
  display: flex;
  cursor: pointer;

  .select-arrow {
    display: inline-block;
    margin-left: auto;
    width: 10px;
    height: 10px;
    margin-top: 9px;
  }
}

#task-description {
  height: 118px;
  border-radius: 12px;
  border: solid 0.5px $app-gray;
  margin-top: 25px;
}

.browse-task-image-wrapper {
  width: 100px;
  height: 100px;
  padding: 10.6px 10.9px 21.1px 23.4px;

  border-radius: 12px;
  border: solid 1px $app-gray;
  background-color: #ffffff;
  position: relative;
  float: left;

  #plus {
    position: absolute;
    width: 7.9px;
    height: 7.9px;
    margin: 0 0 3.5px 3.8px;
    object-fit: contain;
    top: 10px;
    right: 10px;
    color: $app-orange;
  }

  #delete-image-button {
    position: absolute;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid $app-medium-gray;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -13px;
    left: 83px;

    #delete-image {
      width: 15px;
      height: 15px;
    }
  }

  .browse-image-icon {
    position: relative;

    img {
      width: 19.4px;
      height: 16.1px;
      position: absolute;
      top: 15px;
      right: 16px;
      cursor: pointer;
    }

    p {
      position: absolute;
      width: 34px;
      bottom: -23px;
      height: 19px;
      margin: 10px 4px 0 -5px;
      font-family: FuturaBook;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      text-align: left;
      color: $app-gray;
      cursor: pointer;
    }
  }

  #preview-logo {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100px;
    height: 100px;
    border-radius: 12px;
  }
}

#select-no-of-users {
  margin-top: 15px;
  margin: 0 0 18px 0;
  display: flex;
  border-bottom: 1px solid $app-gray;
  padding: 5px 0 15px 0;

  span {
    color: $app-gray;
  }

  span#no-of-users-title {
    margin-left: 10px;
  }
}

#no-of-users-controls {
  position: absolute;
  right: 20px;

  button {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid $app-gray;
    border-radius: 25%;
    color: $app-orange;
    font-weight: bold;
  }

  button:active,
  button:focus {
    border: 1px solid $app-gray;
    outline: none;
  }

  .disabled-button {
    color: $app-gray;
  }

  #no-of-users-display {
    width: 50px !important;
    display: inline-block;
    text-align: center;
  }
}

#save-btn-enabled {
  @extend .disable-select;
  width: 160px;
  height: 46px;
  margin: 15.8px 0 1.2px;
  padding: 13.9px 40px 13.1px 40.1px;
  border-radius: 66px;
  background-color: $app-light-blue;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  cursor: pointer;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  color: #ffffff;
}

#save-btn-disabled {
  @extend .disable-select;
  width: 160px;
  height: 46px;
  margin: 15.8px 0 1.2px;
  padding: 13.9px 40px 13.1px 40.1px;
  border-radius: 66px;
  background-color: #c2ccda;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: normal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: FuturaBook;
  font-stretch: normal;
  font-style: normal;
  line-height: 4.2;
  color: #ffffff;
  cursor: initial;
}

#add-challenge-task-bottom {
  height: 92px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
