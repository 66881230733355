@import '../../common.scss';

.step-to-approve {
  box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin: 10px 15px;
  position: relative;
  padding: 20px;
}

.step-submit-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
}

.step-with-text {
  margin-bottom: 15px;
}

.step-with-image {
  @extend .disable-select;
  width: 270px;
  margin-bottom: 15px;
}

.approve-step-btn {
  @extend .disable-select;
  width: 90px;
  height: 32px;
  padding: 5px 10px;
  margin: 0;
  border-radius: 66px;
  background-color: $app-orange;
  font-size: 14px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: FuturaBook;
  float: right;
}

.decline-step-btn {
  @extend .approve-step-btn;
  color: #000;
  background-color: #ffffff;
  border: solid 1px #212120;
  margin-right: 8px;
}

.approved-step-text {
  @extend .disable-select;
  color: $app-orange;
  font-size: 14px;
  font-weight: bold;
  float: right;
}

.declined-step-text {
  @extend .disable-select;
  color: $app-black;
  font-size: 14px;
  font-weight: bold;
  float: right;
}

#step-to-approve-created {
  display: flex;
  justify-content: space-evenly;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
  border: 1px solid $app-medium-gray;
}

#step-to-approve-user-info {
  margin: 0 0 15px 0;

  .user-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 20px;
    margin-top: -25px;
  }

  .prize-code-user-name-email {
    display: inline-block;

    p {
      margin: 0;
    }
  }
}

.show-all-submits-btn {
  @extend .approve-step-btn;
  float: left;
  color: $app-black;
  background-color: #fff;
  border: solid 1px $app-black;
  margin-right: 8px;
}
