@import '../../common.scss';

#business-logo-list {
  height: 70px;
  width: 70px;
  vertical-align: top;
  position: absolute;
  z-index: 1;
  border-radius: 70px;
  margin-left: 15px;
}

#business-list-type {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  margin-left: 15px;
  margin-top: -14px;
  border-radius: 12px;
}

.business-type-logo {
  height: 16px !important;
  width: 16px !important;
}

.business-logo {
  border-radius: 50%;
  border: 1px solid $app-black;
  height: 70px;
  width: 70px;
}

#business-info-list {
  height: 112px;
  width: 100%;
  margin-right: 0;
  border-radius: 12px;
  padding-left: 90px;
  display: flex;
  align-items: center;
}

#business-name-list {
  margin: 10.2px 74px 3.7px 15.1px;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: $app-black;
  margin-bottom: 10px;
}

#business-address-list {
  margin: 3.7px 0 10.2px 15.1px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: left;
  color: $app-black;
}

#row-business-list {
  @extend .disable-select;
  height: 112px;
  margin: 16px 15px;
  cursor: pointer;
  position: relative;
  display: flex !important;
  align-items: center !important;
  border-radius: 12px;
  border: 1px solid $app-medium-gray;
}

#row-business-list:hover {
  border-radius: 12px;
  border: 1px solid $app-black;
}

#row-business-list-selected {
  @extend #row-business-list;
  border-radius: 12px;
  border: 1px solid $app-black;
}
