@import '../../common.scss';

#row-challenge-list,
#row-challenge-list-selected {
  display: flex;
}

#row-challenge-list {
  @extend .disable-select;
  height: 140px;
  overflow: hidden;
  margin: 10px 15px;
  cursor: pointer;
  position: relative;
  border-radius: 12px;
  border: 1px solid $app-medium-gray;
}

#row-challenge-list:hover {
  border-radius: 12px;
  border: 1px solid $app-black;
}

#row-challenge-list-selected {
  @extend #row-challenge-list;
  border-radius: 12px;
  border: 1px solid $app-black;
}

#challenge-logo-wrapper {
  display: flex;
  align-items: center;
}

#challenge-logo {
  border-radius: 12px;
  height: 140px;
  width: 140px;
}

#challenge-info-wrapper {
  display: inline-block;
  margin: 5px;
}

#challenge-name-text {
  margin: 10px 5px;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: $app-black;
  padding: 0;
}

#challenge-description-text {
  margin: 10px 5px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 0.12px;
  text-align: left;
  color: $app-black;
}

#challenge-participants {
  margin: 15px 5px 10px 5px;
  font-size: 12px;
}

#challenge-date {
  margin: 20px 5px 10px 5px;
  font-size: 12px;

  span {
    color: #f00;
    font-weight: bold;
    font-size: 14px;
  }
}

#prizes-ribbon {
  width: 72px;
  height: 72px;
  background-image: url('/images/red-ribbon.svg');
  position: absolute;
  top: 0;
  right: 0;

  span {
    display: block;
    font-size: 12px;
    color: #fff;
    transform: rotate(45deg);
    margin: 20px 0 0 20px;
  }
}
